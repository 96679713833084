import {
  GetOrganisationsDocument,
  useCreateUserMutation,
  UserType,
} from "@/graphql/types";
import gql from "graphql-tag";
import { Ref } from "vue";
import useParentQuery from "./useParentQuery";

gql`
  mutation CreateUser($input: CreateUserInputType!) {
    createUser(input: $input) {
      ...UserParts
      roles {
        roleId
        name
        application {
          name
        }
      }
    }
  }
`;

export default function (
  organizationId: string,
  user: UserType,
  password: Ref<string | null>
) {
  const mutation = useCreateUserMutation({});

  function call() {
    mutation.mutate(
      {
        input: {
          administrator: user.administrator,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          organizationId: organizationId,
          phone: user.phone,
          password: password.value,
          roleIds:
            user.roles?.flatMap((element) =>
              element?.roleId ? element.roleId : []
            ) ?? [],
        },
      },
      {
        refetchQueries: [{ query: GetOrganisationsDocument }],
        update: (cache, { data: newUser }) => {
          const parentQuery = useParentQuery(organizationId);
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              organization: {
                ...cachedData?.organization,
                users: {
                  count: cachedData?.organization?.users?.count
                    ? cachedData?.organization.users.count + 1
                    : 1,
                  items: [
                    ...(cachedData?.organization?.users?.items ?? []),
                    newUser?.createUser ? newUser.createUser : [],
                  ],
                },
              },
            },
          });
        },
      }
    );
  }

  return {
    call,
    ...mutation,
  };
}
